import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/3QhGnNJrZus"
    bibleGroupSrc="https://pdfhost.io/v/rRzULcVuG_Microsoft_Word_Bible_Group_Homework_214docx.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="Hot and Holy - Love Songs" />
  </Layout>
)

export default SermonPost
